import { forwardRef, useRef, useState } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { ArrowUp } from "lucide-react";
import { ChatHistoryMobile } from "./chatHistory/chatHistoryMobile";
import { motion } from "motion/react";
import { motionConfig } from "@/configs/motion";
import { ChatHistoryDesktopToggle } from "./chatHistory/chatHistoryDesktopToggle";
import { HomeButton } from "./homeButton";

const MotionButton = motion.create(Button, { forwardMotionProps: true });
const MHomeButton = motion.create(HomeButton, { forwardMotionProps: true });
const MChatHistoryToggle = motion.create(ChatHistoryDesktopToggle, {
  forwardMotionProps: true,
});

type InputFieldProps = {
  handleSendMessage: (text: string) => Promise<void>;
  isPending: boolean;
  showHomeButton: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const InputField = forwardRef<HTMLDivElement, InputFieldProps>(
  ({ handleSendMessage, isPending, showHomeButton }, ref) => {
    const textAreaElement = useRef<HTMLTextAreaElement>(null);
    const [text, setText] = useState("");

    const textAreaHeightAdjust = () => {
      if (!textAreaElement.current) {
        return;
      }

      textAreaElement.current.style.height = "auto";
      textAreaElement.current.style.setProperty(
        "height",
        `${textAreaElement.current.scrollHeight}px`,
        "important",
      );
    };

    function handleType(val: string) {
      setText(val);
      textAreaHeightAdjust();
    }

    function handleClick() {
      const msg = text.trim();
      if (msg.length < 1) return;
      handleSendMessage(msg);
      setText("");

      // Resize text box
      if (!textAreaElement.current) return;
      textAreaElement.current.value = "";
      textAreaHeightAdjust();
    }

    return (
      <div
        id="inputfield"
        className="relative z-30 gap-container flex flex-row w-full items-end py-2 px-3 gap-2 max-w-chat rounded-[18px] bg-[#071A3D] mb-4 md:mb-6 shadow-input-field"
        ref={ref}
      >
        {showHomeButton && !text && (
          <MHomeButton
            key="Home-Button"
            className="relative mb-1"
            disabled={!!isPending}
            initial={motionConfig.slideRightInitial}
            animate={motionConfig.animate}
            transition={motionConfig.transition}
          />
        )}
        <MChatHistoryToggle
          className="pb-0.5 mb-1 relative hidden md:inline-flex"
          layout
          transition={motionConfig.transition}
        />
        <ChatHistoryMobile className="pb-0.5 mb-1 relative" />
        <motion.div
          layout
          transition={motionConfig.transition}
          className="relative flex grow flex-row min-h-0 rounded-[14px] p-1 pr-2 py-1 bg-[#00040C] overflow-hidden focus-within:ring-offset-0 focus-within:ring-white focus-within:ring-1"
        >
          <div className="w-full overflow-y-auto max-h-52">
            <Textarea
              id="textInput"
              autoFocus
              placeholder="Ask me anything!"
              rows={1}
              className="block w-full min-h-0 text-base bg-transparent border-none outline-none resize-none overflow-y-none scroll-smooth focus-visible:ring-offset-0 focus-visible:ring-0"
              ref={textAreaElement}
              value={text}
              onChange={(e) => handleType(e.target.value)}
              onKeyUp={(e) => {
                if (isPending) return;
                e.key === "Enter" && !e.shiftKey && handleClick();
              }}
            />
          </div>
          {text && (
            <MotionButton
              id="submit"
              size="icon"
              onClick={handleClick}
              disabled={isPending}
              className={
                "relative ml-1.5 mb-1.5 text-2xs size-7 min-w-7 bg-white hover:bg-gray rounded-full self-end focus-visible:outline focus-visible:outline-2 focus-visible:outline-blue-400"
              }
              title="Send Message"
              initial={motionConfig.slideLeftInitial}
              animate={motionConfig.animate}
              transition={motionConfig.transition}
            >
              <ArrowUp className="text-black size-4" />
            </MotionButton>
          )}
        </motion.div>
      </div>
    );
  },
);
