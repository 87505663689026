import { useSidebar } from "@/hooks/useSidebar";
import { Button } from "../ui/button";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useSession } from "@/hooks/useSession";
import { cn } from "@/utils/cn";
import { TSession } from "@/contexts/sessionContext";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../ui/alert-dialog";
import { Trash } from "../icons/trash";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import { useState, useRef, forwardRef } from "react";
import { toast } from "sonner";
import { Input } from "../ui/input";
import { Pencil } from "../icons/pencil";
import { NewChat } from "../icons/newChat";

type ChatHistoryProps = {
  className: string;
};

export const ChatHistoryDesktop = forwardRef<HTMLDivElement, ChatHistoryProps>(
  ({ className }, ref) => {
    const navigate = useNavigate();
    const { sessionList } = useSession();
    const { setSidebarOpen } = useSidebar();

    const handleNewChat = () => {
      setSidebarOpen(false);
      navigate("/");
    };

    const groupedSessions: Record<string, TSession[]> = sessionList.reduce(
      (acc, session) => {
        if (!acc[session.sessionDate]) {
          acc[session.sessionDate] = [];
        }
        acc[session.sessionDate].push(session);
        return acc;
      },
      {} as Record<string, TSession[]>,
    );

    const sessionsByDate: TSession[][] = Object.values(groupedSessions);

    return (
      <div className={className} ref={ref}>
        <div className="relative z-20 flex flex-col overflow-hidden border bottom-10 h-96 max-h-chat-history bg-history-glass rounded-3xl border-muted shadow-chat-history">
          <div className="flex flex-col flex-grow px-2 overflow-y-auto bg-transparent">
            <h2 className="self-start pl-2 mt-6 mb-2 text-3xl font-light text-nowrap">
              Our History
            </h2>
            <div className="h-full max-h-full pt-4 pr-1 space-y-5 list-none">
              {sessionsByDate
                .slice()
                .reverse()
                .map((sessionGroup) => (
                  <SessionGroup
                    sessionGroup={sessionGroup}
                    key={sessionGroup[0].sessionDate}
                  />
                ))}
            </div>
          </div>
          <div className="flex flex-row justify-center py-3 space-x-1 border-t border-muted bg-primary-dark align-center">
            <Button
              size="icon"
              variant="ghost"
              title="Close History"
              onClick={() => setSidebarOpen(false)}
            >
              <X className="w-full h-full" />
            </Button>
            <Button
              size="icon"
              variant="ghost"
              title="New Conversation"
              onClick={handleNewChat}
            >
              <NewChat className="text-white " />
            </Button>
          </div>
        </div>
      </div>
    );
  },
);

const SessionGroup = ({ sessionGroup }: { sessionGroup: TSession[] }) => {
  return (
    <div className="flex flex-col space-y-1">
      <p className="text-[#9D9D9D] text-left px-4">
        {sessionGroup[0].sessionDate}
      </p>
      {sessionGroup.map((session) => (
        <HistoryItem
          session={session}
          key={session.sessionId}
          className="relative flex flex-row items-center justify-end w-full px-5 rounded-lg"
        />
      ))}
    </div>
  );
};

interface HistoryItem {
  session: TSession;
  className?: string;
}

const HistoryItem = ({ session, className }: HistoryItem) => {
  const isMutating = useIsMutating();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { sessionId, editName, remove } = useSession();
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedName, setEditedName] = useState<string>(session.sessionName);
  const inputRef = useRef<HTMLInputElement>(null);
  const { setMobileSidebarOpen, setSidebarOpen } = useSidebar();

  function handleNameChange() {
    if (!inputRef.current) return;
    setEditedName(inputRef.current.value);
  }

  function handleNameSubmit() {
    editName(session.sessionId, editedName);
    setIsEditingName(false);
  }

  function handleClickSession() {
    if (sessionId === session.sessionId) return;
    queryClient.invalidateQueries({
      queryKey: ["messages", sessionId],
      refetchType: "none",
    });
    setMobileSidebarOpen(false);
    setSidebarOpen(false);
    navigate(`/s/${session.sessionId}`);
  }

  return (
    <div
      className={cn(
        "data-[active=true]:bg-white/25 hover:bg-white/25 transition-all duration-300",
        className,
      )}
      data-active={session.sessionId === sessionId}
    >
      {isEditingName ? (
        <div className="flex flex-col w-full h-full min-w-0 p-0 mr-auto space-y-1 text-left hover:bg-transparent">
          <Input
            type="text"
            value={editedName}
            onChange={handleNameChange}
            onBlur={handleNameSubmit}
            onKeyDown={(e) => e.key === "Enter" && handleNameSubmit()}
            className="relative w-full px-1 py-2 my-0 text-base font-medium right-1 h-fit"
            ref={inputRef}
            autoFocus
          />
        </div>
      ) : (
        <Button
          variant={"ghost"}
          key={session.sessionId}
          disabled={!!isMutating}
          onClick={handleClickSession}
          className="flex flex-col w-full h-full min-w-0 p-0 py-2.5 mr-auto text-left hover:bg-transparent"
        >
          <p className="w-full overflow-hidden text-base whitespace-nowrap text-ellipsis">
            {session.sessionName}
          </p>
        </Button>
      )}
      <Button
        variant="ghost"
        size="icon"
        title="Edit Name"
        className="py-1.5 text-xs rounded-lg h-min"
        onClick={() => setIsEditingName(true)}
      >
        <Pencil />
      </Button>
      <AlertDialog>
        <AlertDialogTrigger asChild={true}>
          <Button
            variant={"ghost"}
            size="icon"
            title="Delete Conversation"
            className="flex flex-row py-1.5 text-xs text-red h-min"
          >
            <Trash />
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent className="p-0 pb-2 rounded-md bg-primary-dark border-white overflow-hidden text-white max-w-[80%] sm:max-w-lg">
          <AlertDialogHeader>
            <AlertDialogTitle className="px-6 py-2 text-white bg-inherit">
              <div>Are you sure? </div>
            </AlertDialogTitle>
            <AlertDialogDescription className="px-6 pt-6 text-left">
              This action cannot be undone. This will permanently delete this
              conversation.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="px-6">
            <AlertDialogCancel className="border-none rounded-xl">
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                remove(session.sessionId);
                toast.success("Conversation has been deleted");
              }}
              className="bg-transparent rounded-xl font-semibold text-red hover:bg-red hover:text-white"
            >
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
          º
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};
