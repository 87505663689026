import { useNavigate, useParams } from "react-router-dom";
import { InputField } from "../components/inputField";
import { Messages } from "../components/messages";
import { Suggestions } from "../components/suggestions";
import { useChat } from "@/hooks/useChat";
import { Toaster } from "@/components/ui/sonner-toast";
import { cn } from "@/utils/cn";
import { useSession } from "@/hooks/useSession";
import { useSidebar } from "@/hooks/useSidebar";
import { ChatHistoryDesktop } from "@/components/chatHistory/chatHistoryDesktop";
import { AnimatePresence, motion } from "motion/react";
import { motionConfig } from "@/configs/motion";

const MSuggestions = motion.create(Suggestions, { forwardMotionProps: true });
const MInputField = motion.create(InputField, { forwardMotionProps: true });
const MChatHistoryDesktop = motion.create(ChatHistoryDesktop, {
  forwardMotionProps: true,
});

const Chat = () => {
  const navigate = useNavigate();
  const { sessionId } = useParams();
  const { isSidebarOpen } = useSidebar();
  const { updateSessionList } = useSession();
  const { messages, streamMessage, isLoading } = useChat(
    sessionId as string,
    "localStorage",
  );

  // Creates and returns a new sessionId if it does not exist (if user is on the home page)
  const createSessionId = (sessionId: string | undefined) => {
    if (!sessionId) {
      // Generate a new sessionId if it doesn't exist and add it to the session list
      const newSessionId = crypto.randomUUID();
      updateSessionList(newSessionId);
      navigate(`/s/${newSessionId}`);
      return newSessionId;
    } else {
      return sessionId;
    }
  };

  // Send message to API endpoint and update history with response
  async function handleSendMessage(text: string) {
    if (!text) return;
    const _sessionId = createSessionId(sessionId);
    streamMessage({
      userMessage: text,
      sessionId: _sessionId,
    });
  }

  return (
    <>
      <div
        className={cn(
          messages.length
            ? "bg-gradient-to-b from-[#080b16] to-blue"
            : "bg-gradient-to-b from-blue to-blue md:bg-desktop",
          "flex flex-col overflow-hidden md:flex-row transition-bg",
        )}
      >
        {!messages.length && (
          <>
            {/* blurred dots */}
            <div className="fixed -top-2 md:top-80 overflow-hidden bg-white -right-20 md:-right-80 size-36 md:size-96 blur-[75px] md:blur-[200px] animate-move-down md:animate-none" />
            <div className="fixed top-[calc(50%-9rem)] md:top-40 bg-[#B1102B] -left-16 md:-left-60 size-36 md:size-96 blur-[100px] md:blur-[200px] animate-move-right md:animate-none" />
          </>
        )}
        <div className="relative flex flex-col items-center w-full text-white h-dvh">
          {messages.length > 0 ? (
            <Messages
              key="messages"
              messages={messages}
              isLoading={isLoading}
            />
          ) : (
            <MSuggestions
              key="suggestions"
              handleSuggestionClick={handleSendMessage}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.2 }}
            />
          )}
          <div className="absolute bottom-0 z-10 flex justify-center w-full px-3 bg-transparent bg-none">
            {/* bottom fade */}
            <div className="absolute bottom-0 left-0 z-10 flex flex-row items-center justify-center w-full pointer-events-none h-bottom-fade bg-bottom-fade" />
            <AnimatePresence mode="sync">
              {isSidebarOpen ? (
                <MChatHistoryDesktop
                  className="absolute bottom-0 z-50 w-full max-w-md px-4 bg-transparent min-h-16"
                  key="ChatHistoryDesktop"
                  initial={motionConfig.shrinkInitial}
                  animate={motionConfig.shrinkAnimate}
                  exit={motionConfig.shrinkExit}
                  style={{
                    originY: "80%", // animate from bottom
                  }}
                />
              ) : (
                <MInputField
                  handleSendMessage={handleSendMessage}
                  isPending={isLoading}
                  showHomeButton={!!messages.length}
                  key="InputField"
                  initial={motionConfig.shrinkInitial}
                  animate={motionConfig.shrinkAnimate}
                  exit={motionConfig.shrinkExit}
                />
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
      <Toaster
        className="pointer-events-auto"
        position="top-center"
        toastOptions={{ className: "bg-[#142645]" }}
      />
    </>
  );
};

export { Chat };
