import React from "react";
import ReactMarkdown, { Options as RemarkProps } from "react-markdown";
import ReactPlayer from "react-player";

export interface MarkdownProps extends RemarkProps {}

// Wrapper to add custom markdown rendering for links
const Markdown: React.FC<MarkdownProps> = ({ children, ...props }) => (
  <ReactMarkdown
    {...props}
    components={{
      p: (props) => {
        // Convert paragraphs to divs to prevent invalid DOM nesting (div in p)
        return <div>{props.children}</div>;
      },
      a: (props) => {
        // embed video links
        return ReactPlayer.canPlay(props.href || "") ? (
          <>
            {props.children}
            <ReactPlayer
              url={props.href}
              controls={true}
              className="!w-full !h-full my-3 [&>*]:my-0 [&>*]:rounded-xl" // [&>*] targets children
            />
          </>
        ) : (
          <a href={props.href}>{props.children}</a> // leave all other links as-is
        );
      },
    }}
  >
    {/* convert break tags into new lines */}
    {children ? children.replace(/<br>/g, "  \n") : null}
  </ReactMarkdown>
);

export default Markdown;
