import { memo, useEffect, useRef, useState } from "react";
import Markdown from "@/components/ui/markdown";
import remarkBreaks from "remark-breaks";
import { TChatMessage } from "@/types/message";
import { ErrorMessage } from "@/components/ui/errorMessage";
import { Map } from "@/components/map/map";
import { LoadingText } from "./loadingText";
import { useIsMutating } from "@tanstack/react-query";
import { cn, debounce } from "@/utils";
import { useAutoScroll } from "@/hooks/useAutoScroll";
import { Button } from "./ui/button";
import { useParams } from "react-router-dom";
import { ArrowDown } from "lucide-react";
import { motion } from "motion/react";

const Messages = memo(
  ({
    messages,
    isLoading,
  }: {
    messages: TChatMessage[];
    isLoading: boolean;
  }) => {
    const { sessionId } = useParams();
    const isMutating = useIsMutating({ mutationKey: ["messages"] });
    const scrollContainer = useRef<HTMLDivElement>(null);
    const [scrollButtonToggled, setScrollButtonToggled] = useState(false);
    useAutoScroll(scrollContainer);

    // Hacky way of hiding scroll button when navigating to different conversation
    useEffect(() => {
      setScrollButtonToggled(false);
    }, [sessionId]);

    const handleScroll = debounce(() => {
      if (!scrollContainer.current) return;
      // Show button when user scrolls 25 pixels from the bottom of the chat
      if (
        scrollContainer.current.scrollTop <
        scrollContainer.current.scrollHeight -
          scrollContainer.current.clientHeight -
          25
      ) {
        setScrollButtonToggled(true);
      } else {
        setScrollButtonToggled(false);
      }
    }, 50);

    return (
      <motion.div
        className="flex flex-col items-end w-full h-full max-w-full px-5 pb-40 mb-auto space-y-8 overflow-x-hidden overflow-y-auto"
        onScroll={handleScroll}
        ref={scrollContainer}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.2 }}
      >
        <div className="sticky top-0 z-40 flex flex-row items-center justify-center w-full py-5">
          <div className="absolute top-0 z-10 h-full -left-5 w-dvw bg-blur" />
          <h3 className="relative z-20 text-xl font-bold tracking-[1.1px]">
            Bulldog Genie
          </h3>
        </div>
        {messages.map((message, index) => {
          // User Message
          return message.owner === "user" ? (
            <div className="flex w-full place-content-center" key={message.id}>
              <div className="flex justify-end w-full max-w-chat">
                <div className="self-end max-w-[90%] py-2.5 px-5 text-left text-wrap text-base break-words whitespace-pre-wrap flex flex-col bg-blue border-[1px] border-[rgba(43,62,94,0.50)] rounded-message">
                  <p>{message.message}</p>
                </div>
              </div>
            </div>
          ) : (
            // Bot Message
            <div className="flex w-full place-content-center" key={message.id}>
              <div className="relative flex flex-row self-start w-full max-w-chat">
                <div className="flex flex-col min-w-0 py-1 text-left break-words bg-transparent rounded-2xl text-wrap">
                  {message.error ? (
                    <ErrorMessage>An error occured</ErrorMessage>
                  ) : (
                    message.message && (
                      <Markdown
                        className={cn(
                          "max-w-full prose prose-invert",
                          !!isMutating &&
                            index === messages.length - 1 &&
                            "loading-dot",
                        )}
                        children={message.message}
                        remarkPlugins={[remarkBreaks]}
                      />
                      /* prose class from tailwindcss-typography allows markdown rendering when using tailwind */
                    )
                  )}
                  {message.directions?.destination &&
                    Object.keys(message.directions.destination).length > 0 && (
                      <Map
                        destinationAddress={message.directions?.destination}
                        originAddress={message.directions?.origin}
                      />
                    )}
                </div>
              </div>
            </div>
          );
        })}
        {isLoading && messages.length % 2 !== 0 && (
          <LoadingText isLoading={isLoading} />
        )}
        <Button
          className={`${
            scrollButtonToggled && sessionId ? "inline-flex" : "hidden"
          } absolute bottom-36 right-1/2 -mr-2 z-10 size-7 rounded-full outline outline-1 outline-white/30 bg-blue hover:bg-blue`}
          size={"icon"}
          onClick={() => {
            setScrollButtonToggled(false);
            if (scrollContainer.current) {
              scrollContainer.current.scrollTo({
                top:
                  scrollContainer.current.scrollHeight -
                  scrollContainer.current.clientHeight,
                behavior: "smooth",
              });
            }
          }}
        >
          <ArrowDown className="text-white size-4" />
        </Button>
      </motion.div>
    );
  },
);

export { Messages };
